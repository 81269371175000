import React, { Fragment, useEffect, useState } from "react";
import Bootstrap from "../../configs/Bootstrap";

export default function RenderTable(props) {
  console.log("Ids", props.selectedItemsArray);
  const makeLink = (props, field = null) => {
    let lastElm = props ? props.split(".").pop() : "";
    const $linkURL = `${Bootstrap.APP_BASE_URL}/viewdetails/${props}/${lastElm}/${field}`;
    return <a href={`${$linkURL}`}>{props}</a>;
  };

  function filterCheck(value) {
    if (
      props &&
      props.selectedItemsArray &&
      props.selectedItemsArray.length > 0
    ) {
      return props.selectedItemsArray.includes(value.id);
    } else {
      return value;
    }
  }
  const finalDataList = props.dataList.filter(filterCheck);

  return (
    <Fragment>
      <table
        id="jobAppTableId"
        style={{
          display: "none",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th>Id</th>
            <th>Resume(Attachment)</th>
            <th>Other Work Attachments</th>
            <th>Full Name</th>
            <th>Total Experience</th>
            <th>Highest Educational Qualification</th>
            <th>Second Highest Educational Qualification</th>
            <th>Postion Applied For</th>
            <th>Contact No</th>
            <th>Email Id</th>
            <th>Gender</th>
            <th>Date of Birth</th>
            <th>Other Professional Qualification</th>
            <th>Current Location</th>
            <th>Preferred Location</th>
            <th>Marital Status</th>
            <th>Dependent</th>
            <th>Family Background</th>
            <th>Currency</th>
            <th>Current Salary</th>
            <th>Expected Salary</th>
            <th>Notice Period</th>
            <th>Current Organisation</th>
            <th>Worked With CSE Earlier</th>
            <th>Opening Source</th>
            <th>Opening Source Other</th>
            <th>Cover Letter</th>
            <th>Applied Date</th>
          </tr>
        </thead>
        <tbody>
          {finalDataList.map((element, i) => {
            // console.log("element rendered >>>", i, element.id);
            return (
              <tr key={i}>
                <td>{element.id}</td>
                <td>{makeLink(element.uploadFile, "uploadFile")}</td>
                <td>{makeLink(element.uploadOtherFile, "uploadOtherFile")}</td>
                <td>{element.fullName}</td>
                <td>{element.totalExperience}</td>
                <td>{element.firstHighestQualification}</td>
                <td>{element.secondHighestQualification}</td>
                <td>{element.jobPostType}</td>
                <td>{element.contactNo}</td>
                <td>{element.emailId}</td>
                <td>{element.gender}</td>
                <td>{element.dob}</td>
                <td>{element.otderQualification}</td>
                <td>{element.currentLocation}</td>
                <td>{element.preferredLocation}</td>
                <td>{element.maritalStatus}</td>
                <td>{element.currentDependent}</td>
                <td>{element.familyBackground}</td>
                <td>{element.currency}</td>
                <td>{element.currentSalary}</td>
                <td>{element.expectedSalary}</td>
                <td>{element.noticePeriod}</td>
                <td>{element.currentOrganisation}</td>
                <td>{element.earlierWokedWitdUs}</td>
                <td>{element.currentOpeningSource}</td>
                <td>{element.currentOpeningSourceOtder}</td>
                <td>{element.coverLetter}</td>
                <td>{element.appliedAt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
}
